import { ErpTableOptions } from '@/types/type';
import { showFiles } from '@/utils/publicMethods';
import { defineComponent, ref } from 'vue';
export default () => {
  const tableRef = ref();

  const columnList: ErpTableOptions[] = [
    {
      label: '日期',
      prop: 'statisticsDate',
      value: [],
      type: 'time',
      filterProp: ['statisticsDateStart', 'statisticsDateEnd'],
    },
    {
      label: '报表名称',
      prop: 'reportName',
      value: '',
      type: 'input',
    },
    {
      label: '报表生成时间',
      prop: 'createdTime',

      value: [],
      type: 'time',
      filterProp: ['createdTimeStart', 'createdTimeEnd'],
    },
    {
      label: '操作',
      prop: '',
      fixed: 'right',
      width: 160,
      isFilter: false,
      type: 'input',
      value: '',
      className: 'erp-btn-groups',
      component: defineComponent({
        template: `
        <el-button   type="primary"   plain  v-if="hasPermission('erp:dailyreport:exportDocumentReport')"
         @click='addContract(row)'>导出</el-button>
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup() {
          const addContract = (row) => {
            showFiles(row.excelName, row.excelUrl, false);
          };
          return { addContract };
        },
      }),
    },
  ];
  return { columnList, tableRef };
};
